/* You can add global styles to this file, and also import other style files */
* {
  font-family: 'Roboto';
  -webkit-tap-highlight-color: transparent;
}

body {
  background-color: #e6e6e6;
}

span {
  margin-bottom: 0 !important;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

.fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.container {
  position: relative;
}

.scroll {
  overflow-y: auto;
  overflow-x: hidden;
}
